import React from "react"
import { createRoot } from "react-dom/client"

import App from "./components/App/App"
import { datadogConfig } from "./config"
import { datadogInit, ErrorBoundary } from "@doktor-se/more-rum-please"
import "./index.scss"

datadogInit(datadogConfig)

const container = document.getElementById("root")
const root = createRoot(container!) // createRoot(container!) if you use TypeScript

root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
)
