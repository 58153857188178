import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { FormattedMessage } from "react-intl"

import VerifyEmailPageHead from "./VerifyEmailPageHead"
import Layout from "components/Layout/Layout"
import { postEmailVerification } from "api/requests"
import Logo from "components/Logo/Logo"
import Loader from "components/Loader/Loader"

import styles from "./VerifyEmailPage.module.scss"
import { ApiError } from "api/api"

const VerifyEmailPage: React.FC = () => {
  const location = useLocation()
  const [verificationState, setVerificationState] = useState<
    "unverified" | "verifying" | "success" | "failure" | "verified"
  >("unverified")

  const searchParams = new URLSearchParams(location.search)
  const verifyCode = decodeURIComponent(searchParams.get("code") || "")
  const redirect = decodeURIComponent(searchParams.get("redirect") ?? "")

  useEffect(() => {
    const SendVerifyCode = async (token: string) => {
      if (token && verificationState === "unverified") {
        try {
          setVerificationState("verifying")
          await postEmailVerification({ token })
          setVerificationState("success")
          if (redirect) {
            setTimeout(() => window.location.replace(redirect), 2000)
          }
        } catch (error) {
          if (error instanceof ApiError && error.httpStatusCode === 409) {
            setVerificationState("verified")
          } else {
            setVerificationState("failure")
          }
        }
      } else if (verificationState !== "success") {
        setVerificationState("failure")
      }
    }

    SendVerifyCode(verifyCode)
  }, [verifyCode, verificationState, redirect])

  const getTitleError = () => {
    switch (verificationState) {
      case "success":
        return "verifyemail.page.success.header"
      case "verified":
        return "verifyemail.page.verified.header"
      default:
        return "verifyemail.page.fail.header"
    }
  }

  const getDescriptionError = () => {
    switch (verificationState) {
      case "success":
        return "verifyemail.page.success.text"
      case "verified":
        return "verifyemail.page.verified.text"
      default:
        return "verifyemail.page.fail.text"
    }
  }

  return (
    <>
      <VerifyEmailPageHead />
      <Layout>
        <Logo />
        {verificationState === "verifying" && <Loader />}
        {verificationState !== "verifying" && (
          <>
            <h1 className={styles.title}>
              <FormattedMessage id={getTitleError()} />
            </h1>
            <p className={styles.description}>
              <FormattedMessage id={getDescriptionError()} />
            </p>
          </>
        )}
      </Layout>
    </>
  )
}

export default VerifyEmailPage
