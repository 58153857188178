import React from "react"
import LandingPageHead from "./LandingPageHead"
import Layout from "components/Layout/Layout"
import styles from "./LandingPage.module.scss"
import appleButton from "./apple-en.svg"
import googleButton from "./google-en.png"
import { useIntl } from "react-intl"
import Logo from "components/Logo/Logo"
import { appleStoreUrl, googlePlayUrl } from "config"

interface LandingPageProps {}

const LandingPage: React.FC<LandingPageProps> = () => {
  const intl = useIntl()
  return (
    <>
      <LandingPageHead />
      <Layout>
        <Logo />
        {googlePlayUrl && (
          <a href={googlePlayUrl}>
            <img
              className={styles.googleButton}
              src={googleButton}
              alt={intl.formatMessage({ id: "googleplay.button" })}
            />
          </a>
        )}
        {appleStoreUrl && (
          <a href={appleStoreUrl}>
            <img
              className={styles.appleButton}
              src={appleButton}
              alt={intl.formatMessage({ id: "applestore.button" })}
            />
          </a>
        )}
      </Layout>
    </>
  )
}

export default LandingPage
