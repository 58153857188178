import { patientApi } from "config"

export class ApiError extends Error {
  code?: string
  details?: any
  localizedMessage?: string

  constructor(
    public httpStatusCode: number,
    apiError: any
  ) {
    super(`An ApiError with status code ${httpStatusCode} occurred.`)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError)
    }

    this.name = "ApiError"
    this.code = apiError?.code
    this.details = apiError?.details
    this.localizedMessage = apiError?.message
  }
}

export const patientApiFetch = async <T = any>(path: string, init?: RequestInit) => {
  const response = await fetch(patientApi + path, init)
  if (!response.ok) {
    const { error } = await response.json()
    throw new ApiError(response.status, error)
  }
  const body = (await response.json()) as T
  return body
}
