export type Environment = "development" | "testing" | "staging" | "production"

export type Platform = "doktor" | "anicura" | "proximus" | "doktorde"

export type ThemeName = "doktor" | "anicura" | "proximus" | "default"

export type FeatureFlags = ""
const featureFlagsEnv = import.meta.env.VITE_APP_FEATURE_FLAGS
export const featureFlags = new Set((featureFlagsEnv?.split(",").map((f: any) => f.trim()) || []) as FeatureFlags[])

export const patientApi = (import.meta.env.VITE_APP_API || "") + (import.meta.env.VITE_APP_SERVICE_ZONE || "")

export const themeName: ThemeName = (import.meta.env.VITE_APP_THEME as ThemeName) || "default"

export const useFallbackLanguage: boolean = import.meta.env.VITE_APP_TRANSLATION_USE_FALLBACK_LANG === "true"

export const translationFileSuffix: string = import.meta.env.VITE_APP_TRANSLATION_FILE_SUFFIX || ""

export const baseLanguage: string = import.meta.env.VITE_APP_TRANSLATION_BASE_LANGUAGE || "en"

const isPlatform = (platform?: string): platform is Platform => {
  const knownPlatforms: Platform[] = ["doktor", "anicura", "proximus", "doktorde"]
  return !!platform && knownPlatforms.includes(platform as Platform)
}
const platformEnv = import.meta.env.VITE_APP_PLATFORM
if (!isPlatform(platformEnv)) {
  throw new Error(`Unknown platform config ${platformEnv}`)
}
export const platform: Platform = platformEnv

const isEnvironment = (environment?: string): environment is Environment => {
  const knownEnvironments: Environment[] = ["development", "staging", "testing", "tuv", "preview" as any, "production"]
  return !!environment && knownEnvironments.includes(environment as Environment)
}
const environmentEnv = import.meta.env.VITE_APP_ENVIRONMENT
if (!isEnvironment(environmentEnv)) {
  throw new Error(`Unknown environment config ${environmentEnv}`)
}
export const environment: Environment = environmentEnv

export const datadogConfig = {
  applicationId: import.meta.env.VITE_APP_DATADOG_APP_ID!,
  clientToken: import.meta.env.VITE_APP_DATADOG_TOKEN!,
  environment,
  partner: platform,
  version: import.meta.env.VITE_APP_VERSION!,
  service: "transactional-ui"
}

export const knownLanguages: string[] = import.meta.env.VITE_APP_TRANSLATION_KNOWN_LANGUAGES?.trim()
  .split(",")
  .map((l: any) => l.trim()) || ["en"]

export const getTranslationFileLanguageCode = (countryCode: string | undefined, languageCode: string | undefined) => {
  if (countryCode === "de" && languageCode === "de") {
    return "de_DE"
  }
  return languageCode
}

let logoUrl: string

export const getLogo = () => {
  const path = `./images/${import.meta.env.VITE_APP_ASSETS}/logo.svg`
  const logoModule = import.meta.glob("./images/**", { eager: true })
  const mod = logoModule[path] as { default: string }
  if (mod && mod.default) {
    logoUrl = mod.default
  }
}

getLogo()
export { logoUrl }

export const googlePlayUrl = import.meta.env.VITE_APP_ANDROID_LINK
export const appleStoreUrl = import.meta.env.VITE_APP_IOS_LINK

export const passwordLength = Number.parseInt(import.meta.env.VITE_APP_PASSWORD_LENGTH || "8")
