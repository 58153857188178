import React, { useState, useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { IntlProvider } from "react-intl"
import { datadogRum } from "@datadog/browser-rum"

import {
  useFallbackLanguage,
  translationFileSuffix,
  baseLanguage,
  knownLanguages,
  getTranslationFileLanguageCode,
  platform
} from "config"

type Translations = { [key: string]: string }

interface InternationalizationProps {
  children: React.ReactElement
}

const fetchLanguage = async (lang: string) => {
  const fileName = lang + translationFileSuffix
  return import(`../../translations/${fileName}.json`)
}

const Internationalization: React.FC<InternationalizationProps> = ({ children }) => {
  const [translations, setTranslations] = useState<Translations>({})
  const [initialized, setInitialized] = useState<boolean>(false)

  const { language: languageFromUrl, country: countryFromUrl } = useParams<{ language?: string; country?: string }>()

  const requestedLanguage = useMemo(() => {
    const language = getTranslationFileLanguageCode(countryFromUrl, languageFromUrl)
    return language && knownLanguages.includes(language) ? language : baseLanguage
  }, [languageFromUrl, countryFromUrl])

  // This is needed since Lokalise formats locale codes with _ while Intl expects -
  const localeCode = useMemo(() => requestedLanguage.replace("_", "-"), [requestedLanguage])

  const setTranslationsState = (value: Translations) => {
    setTranslations(value)
    setInitialized(true)
  }

  useEffect(() => {
    const getTranslations = async () => {
      try {
        // Fetch requested language
        const json = await fetchLanguage(requestedLanguage)
        setTranslationsState(json.default)
        if (useFallbackLanguage && requestedLanguage !== baseLanguage) {
          try {
            // Fetch base language to fill in blanks in request language
            const fallbackJson = await fetchLanguage(baseLanguage)
            setTranslationsState({ ...fallbackJson, ...json })
          } catch {}
        }
      } catch (error: any) {
        datadogRum.addError(error, {
          context: ["ApiError"].includes(error.name) ? error.context || error.url : undefined,
          metadata: {
            status: error.status,
            url: error.url,
            details: error.details,
            platform: platform
          }
        })
        if (requestedLanguage !== baseLanguage) {
          try {
            // Fetch base language when requested language failed
            const json = await fetchLanguage(baseLanguage)
            setTranslationsState(json)
          } catch {
            setTranslationsState({})
          }
        } else {
          setTranslationsState({})
        }
      }
    }
    getTranslations()
  }, [requestedLanguage])

  return (
    <IntlProvider locale={localeCode} messages={translations}>
      {initialized ? children : null}
    </IntlProvider>
  )
}

export default Internationalization
