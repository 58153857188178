import React from "react"
import Internationalization from "components/Internationalization/Internationalization"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"
import AppPathRouter from "./AppPathRouter"

interface AppRouterProps {}

const AppRouter: React.FC<AppRouterProps> = () => {
  return (
    <HelmetProvider>
      <BrowserRouter basename={import.meta.env.BASE_URL}>
        <Switch>
          <Route path="/:country/:language">
            <Internationalization>
              <AppPathRouter />
            </Internationalization>
          </Route>
          <Route path="/">
            <Internationalization>
              <AppPathRouter />
            </Internationalization>
          </Route>
        </Switch>
      </BrowserRouter>
    </HelmetProvider>
  )
}

export default AppRouter
